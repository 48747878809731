import { createAction, props } from '@ngrx/store';
import {
  BackOfficeApplicationsRestorePayload,
  BackOfficeApplicationsSoftDeletePayload,
  BackOfficeGenerateApplicationReport,
  BackOfficeGenerateTransactionsReport,
  BackOfficeGetApplicationsPayload,
  BackOfficeGetPartnersPayload,
  BackOfficeGetTransactionsPayload,
  BackOfficeGetUsersPayload,
  BackOfficeUsersActivateAccountPayload,
  BackOfficeUsersChangePasswordPayload,
  BackOfficeUsersChangePasswordReturn,
  BackOfficeUsersDeactivateAccountPayload,
  BackOfficeUsersPermDeletePayload,
  BackOfficeUsersRestoreAccountPayload,
  BackOfficeUsersSoftDeletePayload,
  Company,
  Language,
  PaginatedResponse,
  Purchase,
  Role,
  User,
  UserProcess,
} from '@interfaces';

export const getRoles = createAction('[BackOffice] Get Roles');
export const getRolesSuccess = createAction('[BackOffice] Get Roles Success', props<{ roles: Role[] }>());
export const getRolesError = createAction('[BackOffice] Get Roles Error', props<{ errorMessage: string }>());

export const getLanguages = createAction('[BackOffice] Get Languages');
export const getLanguagesSuccess = createAction(
  '[BackOffice] Get Languages Success',
  props<{ languages: Language[] }>()
);
export const getLanguagesError = createAction('[BackOffice] Get Languages Error', props<{ errorMessage: string }>());

export const getCompanies = createAction('[BackOffice] Get Companies');
export const getCompaniesSuccess = createAction(
  '[BackOffice] Get Companies Success',
  props<{ companies: Company[] }>()
);
export const getCompaniesError = createAction('[BackOffice] Get Companies Error', props<{ errorMessage: string }>());

export const getEmployees = createAction('[BackOffice] Get Employees');
export const getEmployeesSuccess = createAction('[BackOffice] Get Employees Success', props<{ employees: User[] }>());
export const getEmployeesError = createAction('[BackOffice] Get Employees Error', props<{ errorMessage: string }>());

export const getPartners = createAction(
  '[BackOffice] Get Partners',
  props<{ payload: BackOfficeGetPartnersPayload }>()
);
export const getPartnersSuccess = createAction(
  '[BackOffice] Get Partners Success',
  props<{ partners: Partial<User>[] }>()
);
export const getPartnersError = createAction('[BackOffice] Get Partners Error', props<{ errorMessage: string }>());

export const setSelectedPartners = createAction(
  '[BackOffice] Set Selected Users',
  props<{ payload: { selectedPartners: Partial<User>[] } }>()
);

// ############
// Users
// ############

export const getUsers = createAction('[BackOffice] Get Users', props<{ payload: BackOfficeGetUsersPayload }>());
export const getUsersSuccess = createAction(
  '[BackOffice] Get Users Success',
  props<{ users: PaginatedResponse<User> }>()
);
export const getUsersError = createAction('[BackOffice] Get Users Error', props<{ errorUsersMessage: string }>());

export const usersActivateAccount = createAction(
  '[BackOffice] Activate Account',
  props<{ payload: BackOfficeUsersActivateAccountPayload }>()
);
export const usersActivateAccountSuccess = createAction(
  '[BackOffice] Activate Account Success',
  props<{ activatedAccountID: string }>()
);
export const usersActivateAccountError = createAction(
  '[BackOffice] Activate Account Error',
  props<{ errorUsersMessage: string }>()
);

export const usersDeactivateAccount = createAction(
  '[BackOffice] Deactivate Account',
  props<{ payload: BackOfficeUsersDeactivateAccountPayload }>()
);
export const usersDeactivateAccountSuccess = createAction(
  '[BackOffice] Deactivate Account Success',
  props<{ deactivatedAccountID: string }>()
);
export const usersDeactivateAccountError = createAction(
  '[BackOffice] Deactivate Account Error',
  props<{ errorUsersMessage: string }>()
);

export const usersRestoreAccount = createAction(
  '[BackOffice] Restore Account',
  props<{ payload: BackOfficeUsersRestoreAccountPayload }>()
);
export const usersRestoreAccountSuccess = createAction(
  '[BackOffice] Restore Account Success',
  props<{ restoredAccountID: string }>()
);
export const usersRestoreAccountError = createAction(
  '[BackOffice] Restore Account Error',
  props<{ errorUsersMessage: string }>()
);

export const usersSoftDelete = createAction(
  '[BackOffice] Soft Delete',
  props<{ payload: BackOfficeUsersSoftDeletePayload }>()
);
export const usersSoftDeleteSuccess = createAction(
  '[BackOffice] Soft Delete Success',
  props<{ deletedUsersID: string[] }>()
);
export const usersSoftDeleteError = createAction(
  '[BackOffice] Soft Delete Error',
  props<{ errorUsersMessage: string }>()
);

export const usersPermDelete = createAction(
  '[BackOffice] Permanent Delete',
  props<{ payload: BackOfficeUsersPermDeletePayload }>()
);
export const usersPermDeleteSuccess = createAction(
  '[BackOffice] Permanent Delete Success',
  props<{ deletedUsersID: string[] }>()
);
export const usersPermDeleteError = createAction(
  '[BackOffice] Permanent Delete Error',
  props<{ errorUsersMessage: string }>()
);

export const usersChangePassword = createAction(
  '[BackOffice] Change Password Generate Random',
  props<{ payload: BackOfficeUsersChangePasswordPayload }>()
);
export const usersChangePasswordSuccess = createAction(
  '[BackOffice] Change Password Generate Random Success',
  props<BackOfficeUsersChangePasswordReturn>()
);
export const usersChangePasswordError = createAction(
  '[BackOffice] Change Password Generate Random Error',
  props<{ errorUsersMessage: string }>()
);

// ############
// Transactions
// ############

export const getTransactions = createAction(
  '[BackOffice] Get Transactions',
  props<{ payload: BackOfficeGetTransactionsPayload }>()
);
export const getTransactionsSuccess = createAction(
  '[BackOffice] Get Transactions Success',
  props<{ transactions: PaginatedResponse<Purchase> }>()
);
export const getTransactionsError = createAction(
  '[BackOffice] Get Transactions Error',
  props<{ errorTransactionsMessage: string }>()
);

export const transactionsDownloadPurchaseDocument = createAction(
  '[BackOffice] Download Purchase Document',
  props<{ transactionID: string }>()
);
export const transactionsDownloadPurchaseDocumentSuccess = createAction(
  '[BackOffice] Download Purchase Document Success'
);
export const transactionsDownloadPurchaseDocumentError = createAction(
  '[BackOffice] Download Purchase Document Error',
  props<{ errorTransactionsMessage: string }>()
);

export const transactionsGenerateReport = createAction(
  '[BackOffice] Generate Transactions Report',
  props<{ payload: BackOfficeGenerateTransactionsReport }>()
);
export const transactionsGenerateReportSuccess = createAction('[BackOffice] Generate Transactions Report Success');
export const transactionsGenerateReportError = createAction(
  '[BackOffice] Generate Transactions Report Error',
  props<{ errorTransactionsMessage: string }>()
);

// ############
// Applications
// ############

export const getApplications = createAction(
  '[BackOffice] Get Applications',
  props<{ payload: BackOfficeGetApplicationsPayload }>()
);
export const getApplicationsSuccess = createAction(
  '[BackOffice] Get Applications Success',
  props<{ applications: PaginatedResponse<UserProcess> }>()
);
export const getApplicationsError = createAction(
  '[BackOffice] Get Applications Error',
  props<{ errorApplicationsMessage: string }>()
);

export const applicationsGenerateReport = createAction(
  '[BackOffice] Generate Application Report',
  props<{ payload: BackOfficeGenerateApplicationReport }>()
);
export const applicationsGenerateReportSuccess = createAction('[BackOffice] Generate Application Report Success');
export const applicationsGenerateReportError = createAction(
  '[BackOffice] Generate Application Report Error',
  props<{ errorApplicationsMessage: string }>()
);

export const applicationsSoftDelete = createAction(
  '[BackOffice] Applications Soft Delete',
  props<{ payload: BackOfficeApplicationsSoftDeletePayload }>()
);
export const applicationsSoftDeleteSuccess = createAction(
  '[BackOffice] Applications Soft Delete Success',
  props<{ deletedApplicationsID: string[] }>()
);
export const applicationsSoftDeleteError = createAction(
  '[BackOffice] Applications Soft Delete Error',
  props<{ errorApplicationsMessage: string }>()
);

export const applicationsRestore = createAction(
  '[BackOffice] Applications Restore',
  props<{ payload: BackOfficeApplicationsRestorePayload }>()
);
export const applicationsRestoreSuccess = createAction(
  '[BackOffice] Applications Restore Success',
  props<{ deletedApplicationID: string }>()
);
export const applicationsRestoreeError = createAction(
  '[BackOffice] Applications Restore Error',
  props<{ errorApplicationsMessage: string }>()
);
