<div class="chat-main-window">
  <div *ngIf="!showLoader; else loaderContainer" class="chat-inner-container">
    <div class="close-button-wrapper">
      <span class="close-text">{{ 'CHAT.HOW_CAN_I_HELP' | translate }}</span>
      <mat-icon class="close-button" (click)="goBack()">close</mat-icon>
    </div>
    <div class="chat-messages-container" #chatMessagesContainer>
      <ng-container *ngIf="(chatMessages$ | async)?.length; else noMessages">
        <div
          *ngFor="let chatMessage of chatMessages$ | async; index as chatMessageIndex"
          class="chat-message-wrapper"
          [class.to-the-right]="chatMessage.author.id === myself.id">
          <div
            *ngIf="chatMessage.author.id !== myself.id"
            class="author-avatar"
            [class.author-deleted]="chatMessage.author.deletedAt"
            nz-tooltip
            [nzTooltipTitle]="chatMessage.author.name">
            <mat-icon
              *ngIf="!chatMessage.author.avatarUpdatedAt || chatMessage.author.deletedAt"
              fontSet="material-icons-outlined">
              account_circle
            </mat-icon>
            <img
              *ngIf="chatMessage.author.avatarUpdatedAt && !chatMessage.author.deletedAt"
              class="author-avatar"
              [src]="API_URL + '/user-assets/avatar/' + chatMessage.author.id"
              alt="User Avatar" />
          </div>
          <div
            class="chat-message-content"
            matTooltip="{{ chatMessage.createdAt | date: 'dd.MM.yyyy HH:mm' }}"
            matTooltipClass="chatTooltip"
            matTooltipPosition="left"
            matTooltipShowDelay="350"
            [class.author-messages]="chatMessage.author.id === myself.id"
            [class.interlocutor-messages]="chatMessage.author.id !== myself.id">
            <div [innerHTML]="chatMessage.content | linebreaks"></div>
            <div *ngIf="chatMessage.attachments?.length" class="message-attachments-wrapper">
              <div
                *ngFor="let attachment of chatMessage.attachments; index as chatMessageAttachmentIndex"
                (click)="downloadMessageAttachment(chatMessage.id, attachment.id)"
                class="message-attachment-item">
                <span class="attachment-file-icon" nz-icon nzType="paper-clip" nzTheme="outline"></span>
                <span class="attachment-file-name">{{ attachment.attachment.fileOriginalName }}</span>
              </div>
            </div>
          </div>

          <div
            *ngIf="chatMessage.author.id === myself.id"
            class="author-avatar"
            nz-tooltip
            [nzTooltipTitle]="chatMessage.author.name">
            <mat-icon *ngIf="!chatMessage.author.avatarUpdatedAt" fontSet="material-icons-outlined">
              account_circle
            </mat-icon>
            <img
              *ngIf="chatMessage.author.avatarUpdatedAt"
              class="author-avatar"
              [src]="API_URL + '/user-assets/avatar/' + chatMessage.author.id"
              alt="User Avatar" />
          </div>
        </div>
      </ng-container>
      <ng-template #noMessages>
        <div class="no-messages-wrapper">
          <h2>{{ 'CHAT.NO-MESSAGES-IN-CHAT' | translate }}</h2>
        </div>
      </ng-template>
    </div>
    <div *ngIf="loading$ | async" class="absolute-loader-container">
      <mat-spinner [diameter]="35"></mat-spinner>
    </div>
    <div class="chat-message-input-wrapper standard-form-field">
      <mat-form-field class="chat-message-content-input" appearance="outline">
        <textarea
          matInput
          #chatMessageInput
          id="chat-message-input-in-modal"
          #autosize="cdkTextareaAutosize"
          cdkTextareaAutosize
          cdkAutosizeMinRows="1"
          cdkAutosizeMaxRows="15"
          autocomplete="off"
          autocapitalize="off"
          [(ngModel)]="newMessageContent"
          (keydown.enter)="$event.preventDefault(); submitMessage($event)"
          placeholder="{{ 'CHAT.ENTER_YOUR_MESSAGE' | translate }}"
          maxlength="1500"
          minlength="1">
        </textarea>
        <div class="mat-error" *ngIf="newMessageContent && !messageContentValid(newMessageContent)">
          {{ 'CHAT.FORBIDDEN-CHARACTERS-USED' | translate }}
        </div>
      </mat-form-field>
      <button
        [disabled]="messageAttachments.length === 0 && (!newMessageContent || !messageContentValid(newMessageContent))"
        mat-icon-button
        color="primary"
        aria-label="Submit"
        (click)="submitMessage($event)">
        <mat-icon>send</mat-icon>
      </button>
      <nz-badge [nzCount]="messageAttachments?.length">
        <button nz-button nzType="text" nzSize="default" nzShape="circle" (click)="fileUploadInput.click()">
          <span nz-icon nzType="paper-clip" nzTheme="outline"></span>
        </button>
      </nz-badge>
    </div>
  </div>
  <div class="attachments-section-wrapper" *ngIf="attachmentsToAdd$ | async as attachmentsToAdd">
    <div class="attachments-list-label">
      {{ 'NT3.ATTACHMENTS' | translate }}
    </div>
    <div class="attachments-list">
      <div *ngFor="let item of attachmentsToAdd; index as attachmentIndex" class="attachment-asset">
        <ng-container *ngIf="item.uploaded && item.userAsset?.id">
          <span class="blued-text">{{ item.fileOriginalName }}</span>
          <mat-icon (click)="removeAsset(item.userAsset)" class="negative">close</mat-icon>
        </ng-container>
        <ng-container *ngIf="item.loading">
          <span class="in-progress-text">
            {{ item.fileOriginalName }}
          </span>
          <mat-spinner [diameter]="25" color="primary"></mat-spinner>
        </ng-container>
        <ng-container *ngIf="item.error">
          <span class="error-text">
            {{ item.fileOriginalName }}
          </span>
          <mat-icon fontSet="material-icons-outlined" class="error-icon">error</mat-icon>
        </ng-container>
      </div>
    </div>
  </div>
  <input
    (change)="uploadFiles($event)"
    #fileUploadInput
    type="file"
    class="file-input-field"
    multiple
    id="attachments-input" />

  <ng-template #loaderContainer>
    <div class="loader-container">
      <mat-spinner *ngIf="showLoader" [diameter]="35"></mat-spinner>
    </div>
  </ng-template>
</div>
