import { Injectable, OnDestroy } from '@angular/core';
import { ReplaySubject, takeUntil } from 'rxjs';
import { DeviceInfoService } from '@core/services/device-info.service';
import { AllDeviceInfo } from '@interfaces';
import { HttpResponse } from '@angular/common/http';
import { SnackbarService } from '@shared/snack-bar/snack-bar.service';

@Injectable({
  providedIn: 'root',
})
export class GetFileService implements OnDestroy {
  private destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  public deviceInfo: AllDeviceInfo;

  public OPENABLE_EXTENSIONS = ['.pdf', '.jpeg', '.jpg', '.png', '.bmp'];
  public DOWNLOABLE_EXTENSIONS = ['.zip', '.rar', '.xlsx', '.xls', '.doc', '.docx'];

  constructor(
    private readonly deviceInfoService: DeviceInfoService,
    private readonly notification: SnackbarService
  ) {
    this.deviceInfo = this.deviceInfoService.getInfo();

    this.deviceInfoService.infoEmitter.pipe(takeUntil(this.destroy$)).subscribe(info => {
      this.deviceInfo = info;
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public getFile(file: HttpResponse<Blob>, forceDownload?: boolean): boolean {
    if (!forceDownload) {
      forceDownload = false;
    }

    const fileName = file.headers.get('File-Name');
    const fileExtension = file.headers.get('File-Extension')?.toString()?.toLowerCase();

    const deviceInfo = this.deviceInfoService.getInfo();

    const isOpenableInTab = this.OPENABLE_EXTENSIONS.includes(fileExtension);
    const shouldDownloadInsteadOpen = this.DOWNLOABLE_EXTENSIONS.includes(fileExtension);

    if (isOpenableInTab && deviceInfo.deviceTypeDetected === 'DESKTOP' && forceDownload === false) {
      const newWindow = window.open();
      newWindow.addEventListener('DOMContentLoaded', () => (newWindow.document.title = fileName));
      newWindow.document.write(`
            <style>body {margin: 0 !important;}</style>
            <iframe src="${URL.createObjectURL(
              file.body
            )}" frameborder="0" style="border:0; top:0; left:0; bottom:0; right:0; width:100%; height:100%;" allowfullscreen>
            </iframe>
            `);
      newWindow.document.title = fileName;
    }

    if (shouldDownloadInsteadOpen || deviceInfo.deviceTypeDetected !== 'DESKTOP' || forceDownload) {
      const url = window.URL.createObjectURL(file.body);
      const anchor = document.createElement('a');
      anchor.download = fileName;
      anchor.href = url;
      anchor.click();
      anchor.remove();
    }

    if (!isOpenableInTab && !shouldDownloadInsteadOpen) {
      this.notification.showError('NT3.UNSUPPORTED_TYPE_OF_FILE_CONTACT_ADMIN');
      return false;
    }

    return true;
  }
}
