import { QueueRequestType, ROLES_KEYS, UserProcessMode, UserProcessStatus } from '@interfaces';

export const fullDateFormat = 'DD.MM.yyyy';
export const fullDateFormatTimestamp = 'YYYY-MM-DD';

export const PL_LETTERS_ONLY_REGEX =
  /^[ \-A-Za-zAaĄąBbCcĆćDdEeĘęFfGgHhIiJjKkLlŁłMmNnŃńOoÓóPpRrSsŚśTtUuWwYyZzŹźŻżQqXxVv]+$/;
export type PatternName =
  | 'PLTextValidator'
  | 'PhoneNumberValidator'
  | 'PLNameValidator'
  | 'DateValidator'
  | 'ZipValidator'
  | 'CommentTextValidator'
  | 'VisitDateValidator'
  | 'HasUpperCaseLetter'
  | 'HasSpecialCharacter'
  | 'LatinAndCyrylicName'
  | 'LatinAndCyrylicText';

export const patterns: {
  LatinAndCyrylicName: RegExp;
  LatinAndCyrylicText: RegExp;
  PLNameValidator: RegExp;
  PLTextValidator: RegExp;
  DateValidator: RegExp;
  ZipValidator: RegExp;
  CommentTextValidator: RegExp;
  VisitDateValidator: RegExp;
  HasUpperCaseLetter: RegExp;
  HasSpecialCharacter: RegExp;
  PhoneNumberValidator: RegExp;
} = {
  LatinAndCyrylicName:
    /^[ \-A-Za-z0-9AaĄąBbCcĆćDdEeĘęFfGgHhIiJjKkLlŁłMmNnŃńOoÓóPpRrSsŚśTtUuWwYyZzŹźŻżQqXxVv'"\p{sc=Cyrillic}]+$/u,
  LatinAndCyrylicText:
    /^[ \n\-A-Za-z0-9AaĄąBbCcĆćDdEeĘęFfGgHhIiJjKkLlŁłMmNnŃńOoÓóPpRrSsŚśTtUuWwYyZzŹźŻżQqXxVv'",.?:/+\p{sc=Cyrillic}]+$/u,
  PLNameValidator: /^[ \-A-Za-z0-9AaĄąBbCcĆćDdEeĘęFfGgHhIiJjKkLlŁłMmNnŃńOoÓóPpRrSsŚśTtUuWwYyZzŹźŻżQqXxVv'"]+$/,
  PLTextValidator: /^[ \n\-A-Za-z0-9AaĄąBbCcĆćDdEeĘęFfGgHhIiJjKkLlŁłMmNnŃńOoÓóPpRrSsŚśTtUuWwYyZzŹźŻżQqXxVv'",.?:/+]+$/,
  PhoneNumberValidator: /^[ \n0-9\-\+]+$/,
  CommentTextValidator:
    /^[ \S\r\n\-A-Za-z0-9AaĄąBbCcĆćDdEeĘęFfGgHhIiJjKkLlŁłMmNnŃńOoÓóPpRrSsŚśTtUuWwYyZzŹźŻżQqXxVv'",.?;:/!@#$%^&*\(\)_+=\[\]]+$/,
  DateValidator:
    /^(((0[1-9]|[12]\d|3[01])\.(0[13578]|1[02])\.((19|[2-9]\d)\d{2}))|((0[1-9]|[12]\d|30)\.(0[13456789]|1[012])\.((19|[2-9]\d)\d{2}))|((0[1-9]|1\d|2[0-8])\.02\.((19|[2-9]\d)\d{2}))|(29\.02\.((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|(([1][26]|[2468][048]|[3579][26])00))))$/,
  ZipValidator: /^[0-9][0-9][0-9][0-9][0-9]$/,
  VisitDateValidator: /^[0-9/.]+$/,
  HasUpperCaseLetter: /[A-Z]/,
  HasSpecialCharacter: /[!@#$%^&*()_+\-\?.]/,
};

export type AvailableLanguages = 'pl' | 'en' | 'uk' | 'ru';
export const SUPPORTED_LANGS = ['pl', 'en', 'uk', 'ru'];
export const langKeyToLocale = {
  pl: 'pl',
  en: 'en-GB',
  uk: 'uk',
  ru: 'ru',
};
export const defaultLanguages = [
  { id: 'ecd424ee-9222-49fd-a43f-04123a4800c7', name: 'Polski', key: 'pl', flagFile: null as any },
  { id: 'e563d944-5f07-462c-83b3-b16e8fb2dc53', name: 'English', key: 'en', flagFile: null },
  { id: '238bdbaf-77a0-4261-93df-b4182d3e2cd7', name: 'Русский', key: 'ru', flagFile: null },
  { id: '3d7eb76d-e22e-4b21-878b-87b5288434e1', name: 'українська', key: 'uk', flagFile: null },
];

export const FILE_SIZE_LIMIT = 1024 * 1024 * 15; // Bytes * kBytes = 1MB * 15 = 15MBytes
export const UPLOAD_LIMITS = {
  files: 1,
  fields: 10,
  fileSize: FILE_SIZE_LIMIT,
};
export const ALLOWED_FILE_TYPES = [
  'application/pdf',
  'image/jpeg',
  'image/jpg',
  'image/png',
  'image/svg+xml',
  'image/bmp',
  'image/apng',
];

export const ALLOWED_FILES_IN_CHAT = [
  'application/pdf',
  'image/jpeg',
  'image/jpg',
  'image/png',
  'image/svg+xml',
  'image/bmp',
  'image/apng',
  'zip',
  'application/zip',
  'application/vnd.zip',
  'application/x-zip',
  'application/x-zip-compressed',
  'application/vnd.rar',
  'application/x-rar',
  'application/x-rar-compressed',
  'application/msword', // DOC
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // DOCX
  'application/vnd.ms-excel', // XLS
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // XLSX
];

export const roleToPath = {
  [ROLES_KEYS.Admin]: 'management',
  [ROLES_KEYS.Team_Leader]: 'management',
  [ROLES_KEYS.Employee]: 'management',
  [ROLES_KEYS.Foreigner]: 'user-process',
  [ROLES_KEYS.Employer]: 'manager',
  [ROLES_KEYS.Field_Consultant]: 'management/visits-list',
};

export const highEmployeeRoles = [ROLES_KEYS.Admin, ROLES_KEYS.Team_Leader, ROLES_KEYS.Employee];
export const allEmployeeRoles = [
  ROLES_KEYS.Admin,
  ROLES_KEYS.Employee,
  ROLES_KEYS.Team_Leader,
  ROLES_KEYS.Field_Consultant,
];

export const requiredPersonalDetails = ['name', 'surname', 'sex', 'dateOfBirth', 'residenceVoivodeshipDetailsId'];

export const statusesAllowedPerPath = {
  preSurvey: [UserProcessStatus.FILLING_PRE_SURVEY, UserProcessStatus.PRE_SURVEY_VERIFICATION],
  personalDetails: [
    UserProcessStatus.FILLING_DETAILS,
    UserProcessStatus.CHANGES_REQUESTED,
    UserProcessStatus.COMPLETING_DOCUMENTS,
  ],
  documents: [
    UserProcessStatus.CHANGES_REQUESTED,
    UserProcessStatus.COMPLETING_DOCUMENTS,
    UserProcessStatus.READY_FOR_REVIEW,
  ],
  summary: [
    UserProcessStatus.REVIEWED_READY_TO_PRINT,
    UserProcessStatus.REVIEWED_READY_TO_PRINT_WAITING_FOR_DOCUMENTS_SIGNATURE,
    UserProcessStatus.DOCUMENTS_SIGNED_WAITING_FOR_VOIVO_OFFICE_VISIT_DATE,
    UserProcessStatus.OFFICE_VISIT_AVAILABILITY_PROVIDED,
    UserProcessStatus.OFFICE_VISIT_SCHEDULED,
    UserProcessStatus.APPROVED,
    UserProcessStatus.APPLICATION_SUBMITTED,
    UserProcessStatus.DECISION_ISSUED,
    UserProcessStatus.ARCHIVED,
  ],
};

export const JOBS_REQUIRED_FOR_PROCESS_TO_FINISH = [
  QueueRequestType.IMAGES_TRANSFORM,
  QueueRequestType.DOCUMENTS_FILL,
  QueueRequestType.MERGING_PDFS,
];

export const statusesForbiddenToSetManually: UserProcessStatus[] = [];

export enum FontSize {
  SMALL = 'F_SM',
  MEDIUM = 'F_M',
  LARGE = 'F_L',
}

export const FontSizeToPx = {
  [FontSize.SMALL]: '0px',
  [FontSize.MEDIUM]: '2px',
  [FontSize.LARGE]: '4px',
};

export const monthsKeys = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'];

export const CHECKLIST_DOC_KEY = 'CHECKLIST_SHARED';
export const PROXY_DOC_KEY = 'PROXY';
export const ADDITIONAL_DOCUMENTS_KEY = 'ADDITIONAL_DOCUMENTS';

export const USER_PROCESS_STATUS_FOR_MANAGERS = {
  [UserProcessStatus.FILLING_PRE_SURVEY]: UserProcessStatus.FILLING_DETAILS,
  [UserProcessStatus.PRE_SURVEY_VERIFICATION]: UserProcessStatus.FILLING_DETAILS,
  [UserProcessStatus.FILLING_DETAILS]: UserProcessStatus.FILLING_DETAILS,
  [UserProcessStatus.COMPLETING_DOCUMENTS]: UserProcessStatus.COMPLETING_DOCUMENTS,
  [UserProcessStatus.READY_FOR_REVIEW]: UserProcessStatus.COMPLETING_DOCUMENTS,
  [UserProcessStatus.CHANGES_REQUESTED]: UserProcessStatus.CHANGES_REQUESTED,
  [UserProcessStatus.APPROVED]: UserProcessStatus.OFFICE_VISIT_AVAILABILITY_PROVIDED,
  [UserProcessStatus.REVIEWED_READY_TO_PRINT]: UserProcessStatus.OFFICE_VISIT_AVAILABILITY_PROVIDED,
  [UserProcessStatus.OFFICE_VISIT_AVAILABILITY_PROVIDED]: UserProcessStatus.OFFICE_VISIT_AVAILABILITY_PROVIDED,
  [UserProcessStatus.OFFICE_VISIT_SCHEDULED]: UserProcessStatus.OFFICE_VISIT_SCHEDULED,
  [UserProcessStatus.APPLICATION_SUBMITTED]: UserProcessStatus.APPLICATION_SUBMITTED,
  [UserProcessStatus.DECISION_ISSUED]: UserProcessStatus.DECISION_ISSUED,
  [UserProcessStatus.ARCHIVED]: UserProcessStatus.ARCHIVED,
};

export const disabledStatusesWhenMode = {
  // STANDARD mode assumes that Foreigner will go to the voivodeship office for the application submition
  // and he will bring his signed documents with him
  [UserProcessMode.STANDARD]: [
    UserProcessStatus.REVIEWED_READY_TO_PRINT_WAITING_FOR_DOCUMENTS_SIGNATURE, //6b
    UserProcessStatus.DOCUMENTS_SIGNED_WAITING_FOR_VOIVO_OFFICE_VISIT_DATE, //7b
  ],
  // SEMI_POST mode assumes that Foreigner will send signed documents to the company office OR
  // will come to signe the documents, then company office will submit his application by sending with post
  // then foreigner has to schedule office visit to submit fingerprints
  [UserProcessMode.SEMI_POST]: [
    UserProcessStatus.REVIEWED_READY_TO_PRINT, //6a
    UserProcessStatus.OFFICE_VISIT_AVAILABILITY_PROVIDED, //7a
  ],
  // UKR mode assumes that Foreigner will send signed documents to the company office OR
  // will come to sign the documents, then company office will submit his application by sending with post
  // then foreigner awaits for the decision and process finishes - UKR mode assumes that fingerprints were submitted before the temp. res. permit application start
  [UserProcessMode.UKR]: [
    UserProcessStatus.REVIEWED_READY_TO_PRINT, //6a
    UserProcessStatus.OFFICE_VISIT_AVAILABILITY_PROVIDED, //7a
    UserProcessStatus.DOCUMENTS_SIGNED_WAITING_FOR_VOIVO_OFFICE_VISIT_DATE, //7b
    UserProcessStatus.OFFICE_VISIT_SCHEDULED, //8
  ],
};

export const userProcessStatusToIndex = {
  [UserProcessStatus.FILLING_PRE_SURVEY]: '1',
  [UserProcessStatus.PRE_SURVEY_VERIFICATION]: '2',
  [UserProcessStatus.FILLING_DETAILS]: '3',
  [UserProcessStatus.COMPLETING_DOCUMENTS]: '4',
  [UserProcessStatus.READY_FOR_REVIEW]: '5',
  [UserProcessStatus.CHANGES_REQUESTED]: '6',
  [UserProcessStatus.APPROVED]: '7',
  [UserProcessStatus.REVIEWED_READY_TO_PRINT]: '8A',
  [UserProcessStatus.REVIEWED_READY_TO_PRINT_WAITING_FOR_DOCUMENTS_SIGNATURE]: '8B',
  [UserProcessStatus.OFFICE_VISIT_AVAILABILITY_PROVIDED]: '9A',
  [UserProcessStatus.DOCUMENTS_SIGNED_WAITING_FOR_VOIVO_OFFICE_VISIT_DATE]: '9B',
  [UserProcessStatus.OFFICE_VISIT_SCHEDULED]: '10',
  [UserProcessStatus.APPLICATION_SUBMITTED]: '11',
  [UserProcessStatus.DECISION_ISSUED]: '12',
  [UserProcessStatus.ARCHIVED]: '13',
};

export const CALENDAR_DATE_FORMAT = 'DD.MM.yyyy';
export const CALENDAR_FULLDATE_FORMAT = `${CALENDAR_DATE_FORMAT} HH:mm`;

export const legalBasisOfWork = [
  'DELEGOWANIE',
  'INNA',
  'KONTRAKT MENEDŻERSKI',
  'MIANOWANIE',
  'POWOŁANIE',
  'SPÓŁDZIELCZA UMOWA O PRACĘ',
  'UMOWA AGENCYJNA',
  'UMOWA O DZIEŁO',
  'UMOWA O POMOCY PRZY ZBIORACH',
  'UMOWA O PRACĘ',
  'UMOWA O PRACĘ NA CZAS NIEOKREŚLONY',
  'UMOWA O PRACĘ NA CZAS OKREŚLONY',
  'UMOWA O PRACĘ NA OKRES PRÓBNY',
  'UMOWA O PRACĘ W ZASTĘPSTWIE',
  'UMOWA O ŚWIADCZENIE USŁUG',
  'UMOWA ZLECENIE',
];

// =================================================================================== APPLICATION DETAILS MAT-TABS CONFIG
export enum APP_DETAILS_TAB {
  'GENERAL' = 'GENERAL',
  'PRE_SURVEY' = 'PRE_SURVEY',
  'PERSONAL_DATA_FORM' = 'PERSONAL_DATA_FORM',
  'DOCS' = 'DOCS',
  'APPENDIX' = 'APPENDIX',
  'SUMMARY' = 'SUMMARY',
}

// Use this to construct correct queryParams in link referencing one of mat-tab inside management/application-details/...
export const APP_DETAILS_TAB_TO_INDEX: { [key: string]: number } = {
  [APP_DETAILS_TAB.GENERAL]: 0,
  [APP_DETAILS_TAB.PRE_SURVEY]: 1,
  [APP_DETAILS_TAB.PERSONAL_DATA_FORM]: 2,
  [APP_DETAILS_TAB.DOCS]: 3,
  [APP_DETAILS_TAB.APPENDIX]: 4,
  [APP_DETAILS_TAB.SUMMARY]: 5,
};

export const getAppDetailsTabNameByTabIndex = (index: number): APP_DETAILS_TAB => {
  const [tabName] = Object.entries(APP_DETAILS_TAB_TO_INDEX).find(([, tabIndex]) => {
    return tabIndex === index;
  });
  return tabName as APP_DETAILS_TAB;
};
// =================================================================================== APPLICATION DETAILS MAT-TABS CONFIG END
